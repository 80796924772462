@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #edeae4;
}

h1 {
  font-family: 'Unbounded', sans-serif;
}

p,
a {
  font-family: 'Work Sans', sans-serif;
}
